<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      clip-path="url(#clip0_15912_73133)"
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".3"
    >
      <path
        d="M21.612 13.16c-.351-.002-.706.086-.99.157a3.39 3.39 0 0 1-.402.085c-.523.052-1.117-.122-1.745-.306-.533-.157-1.082-.318-1.647-.36-.135-.353-.27-.645-.396-.918-.35-.755-.655-1.41-.772-3.032.429-.475.747-1.06 1.102-1.715.092-.17.187-.345.288-.526.59-1.057 1.125-2.378 1.635-4.04A.39.39 0 0 0 18.31 2H5.69a.39.39 0 0 0-.374.505c.51 1.662 1.045 2.983 1.635 4.04.1.18.196.356.288.526.355.655.673 1.24 1.102 1.715-.117 1.622-.421 2.277-.771 3.032-.127.273-.262.565-.397.918-.565.042-1.114.203-1.647.36-.628.184-1.222.358-1.745.306-.1-.01-.246-.047-.402-.085-.284-.071-.638-.158-.99-.157a.39.39 0 0 0-.388.39v8.06c0 .215.175.39.39.39h19.22a.39.39 0 0 0 .39-.39v-8.06a.39.39 0 0 0-.388-.39ZM7.632 6.164c-.503-.9-.966-2.01-1.41-3.383h11.557c-.445 1.373-.908 2.483-1.41 3.383-.104.185-.2.363-.294.535-.898 1.655-1.392 2.567-4.075 2.567-2.683 0-3.177-.912-4.075-2.567-.093-.172-.19-.35-.293-.535Zm13.587 15.055H12.39v-1.035a.39.39 0 0 0-.782 0v1.035H2.781v-7.237c.14.026.278.06.409.093.183.045.356.088.512.104.674.068 1.37-.136 2.043-.334.382-.112.772-.226 1.155-.29l-.06.222a.39.39 0 1 0 .755.198c.216-.823.453-1.334.682-1.828.326-.702.636-1.37.79-2.747.66.41 1.562.647 2.933.647 1.37 0 2.273-.237 2.932-.647.155 1.376.465 2.045.79 2.747.23.494.467 1.005.683 1.828a.39.39 0 1 0 .756-.198l-.06-.221c.382.063.772.177 1.154.29.673.197 1.369.4 2.043.333.156-.016.329-.059.512-.104.13-.033.27-.067.409-.093v7.237Z"
      />
      <path
        d="M7.487 15.83c-.28.022-.596.047-.955.068a.39.39 0 1 0 .046.78c.367-.022.688-.047.97-.07 1.452-.114 1.797-.142 2.633.694a.39.39 0 0 0 .552 0 .39.39 0 0 0 0-.552c-1.09-1.09-1.722-1.041-3.246-.92ZM19.202 15.95c-1.177 0-2.016-.067-2.69-.12-1.524-.121-2.155-.171-3.245.92a.39.39 0 0 0 .552.552c.836-.836 1.18-.808 2.632-.694.651.052 1.543.123 2.751.123a.39.39 0 0 0 0-.782ZM10.453 11.59a.39.39 0 0 0-.781 0c0 1.323.358 2.647.548 3.162a.39.39 0 0 0 .733-.27c-.2-.546-.5-1.767-.5-2.892ZM13.414 15.008a.39.39 0 0 0 .367-.256c.19-.515.548-1.839.548-3.162a.39.39 0 0 0-.781 0c0 1.125-.3 2.346-.5 2.892a.39.39 0 0 0 .366.526ZM4.797 15.95a.39.39 0 1 0 0 .78.39.39 0 0 0 0-.78Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_15912_73133">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
</template>
