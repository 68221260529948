<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".3"
      clip-path="url(#a)"
    >
      <path
        d="M6.286 2a.39.39 0 0 0-.39.39v.878a8.445 8.445 0 0 1-.97 3.922 15.032 15.032 0 0 0-1.655 5.427 15.032 15.032 0 0 0 .465 5.655l.957 3.442a.39.39 0 0 0 .753-.21l-.957-3.441a14.255 14.255 0 0 1-.442-5.362 14.255 14.255 0 0 1 1.57-5.147 9.229 9.229 0 0 0 1.06-4.286v-.877A.39.39 0 0 0 6.286 2ZM18.44 5.633a8.407 8.407 0 0 1-.338-2.365v-.877a.39.39 0 0 0-.782 0v.877c0 .875.125 1.745.371 2.585a.39.39 0 0 0 .75-.22ZM20.794 13.506a15.075 15.075 0 0 0-.98-4.672.39.39 0 0 0-.73.28 14.292 14.292 0 0 1 .422 8.949l-.957 3.442a.39.39 0 0 0 .753.209l.957-3.442a15.07 15.07 0 0 0 .535-4.766ZM12 5.672c.646 0 1.172-.526 1.172-1.172a.39.39 0 0 0-.781 0 .391.391 0 0 1-.782 0 .39.39 0 0 0-.78 0c0 .646.525 1.172 1.171 1.172ZM15.107 7.324a3.153 3.153 0 0 0-3.11.355 3.153 3.153 0 0 0-3.109-.355c-.791.341-1.42.998-1.723 1.803a2.978 2.978 0 0 0 .084 2.344c.893 1.89 3.632 3.882 4.358 4.387v5.751a.39.39 0 0 0 .781 0v-5.75c.726-.507 3.465-2.499 4.358-4.388a2.978 2.978 0 0 0 .084-2.344 3.208 3.208 0 0 0-1.723-1.803Zm.932 3.813c-.78 1.652-3.287 3.507-4.042 4.04-.755-.533-3.26-2.388-4.042-4.04a2.203 2.203 0 0 1-.06-1.734 2.423 2.423 0 0 1 1.302-1.361 2.37 2.37 0 0 1 2.536.43.39.39 0 0 0 .528 0 2.37 2.37 0 0 1 2.537-.43 2.422 2.422 0 0 1 1.301 1.36 2.203 2.203 0 0 1-.06 1.735Z"
      />
      <path
        d="M13.443 10.252h-.268v-.268a.74.74 0 0 0-.74-.74h-.864a.74.74 0 0 0-.74.74v.268h-.267a.74.74 0 0 0-.74.74v.864c0 .408.332.74.74.74h.267v.267c0 .408.332.74.74.74h.865a.74.74 0 0 0 .74-.74v-.267h.267a.74.74 0 0 0 .74-.74v-.865a.74.74 0 0 0-.74-.74Zm-.042 1.562h-.617a.39.39 0 0 0-.39.391v.617h-.781v-.617a.39.39 0 0 0-.391-.39h-.617v-.782h.617a.39.39 0 0 0 .39-.39v-.617h.782v.617c0 .215.175.39.39.39h.617v.781ZM18.727 7.763a.39.39 0 1 0 0-.781.39.39 0 0 0 0 .78Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M2 2h20v20H2z" /></clipPath>
    </defs>
  </svg>
</template>
