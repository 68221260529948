<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path
      :transform="`scale(${scale})`"
      :fill="props.color"
      d="m8.333 13.643-1.014 2.23a.178.178 0 0 1-.088.09l-1.875.851a.178.178 0 0 0 0 .324l1.875.852c.039.018.07.05.088.088l.852 1.875c.064.14.26.14.324 0l.852-1.875c.018-.039.05-.07.089-.088l1.874-.852a.178.178 0 0 0 0-.324l-1.935-.88M15.177 4l-1.48 3.254a.178.178 0 0 1-.087.088L10.71 8.66a.178.178 0 0 0 0 .323L13.61 10.3c.039.018.07.05.088.089l1.317 2.898c.063.139.26.139.324 0l1.317-2.898c.018-.04.05-.07.088-.088l2.898-1.318a.178.178 0 0 0 0-.323l-2.958-1.345"
    />
  </svg>
</template>
