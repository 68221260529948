<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => {
  return props.size / DEFAULT_SIZE
})
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    fill="none"
  >
    <g :transform="`scale(${scale})`">
      <path
        :fill="props.color"
        fill-rule="evenodd"
        :stroke="props.color"
        stroke-width=".3"
        d="M17.757 8.009c-1.663 2.204-3.257 5.184-3.257 8.366 0 .887.221 1.352.43 1.591.212.242.438.284.508.284.161 0 .412-.003.619-.092a.51.51 0 0 0 .224-.17.609.609 0 0 0 .094-.363c0-.198-.033-.332-.074-.432a1.096 1.096 0 0 0-.17-.271l-.045-.055c-.14-.169-.336-.407-.336-.805 0-2.73 1.042-5.439 2.184-7.606a29.19 29.19 0 0 1 1.29-2.198c-.463.499-.967 1.088-1.467 1.75Zm3.184-4.113a.372.372 0 0 1 .122-.021.312.312 0 0 1 .25.5l-.053.07c-.503.67-1.692 2.253-2.773 4.302-1.122 2.13-2.112 4.733-2.112 7.316 0 .166.057.237.2.413l.044.055c.085.106.184.24.26.423.075.183.121.4.121.671 0 .289-.073.53-.208.722a1.13 1.13 0 0 1-.49.386c-.332.142-.698.142-.855.142h-.01c-.242 0-.642-.114-.977-.497-.338-.386-.585-1.015-.585-2.003 0-3.38 1.687-6.494 3.383-8.743a22.684 22.684 0 0 1 2.383-2.689c.334-.32.624-.572.842-.745.108-.087.203-.157.28-.207.037-.025.075-.048.112-.066.018-.01.04-.02.066-.029Z"
        clip-rule="evenodd"
      />
      <path
        :fill="props.color"
        fill-rule="evenodd"
        d="M12.205 15.867a7.924 7.924 0 0 0-1.451-.117h-.004c-.178 0-.354.002-.527.005h-.003a.517.517 0 0 0-.51.49.15.15 0 0 0 .026.091c.3.441.455.964.445 1.497 0 .519-.165 1.094-.577 1.543-.418.457-1.061.75-1.95.75-.458 0-1.092-.181-1.614-.69-.529-.517-.915-1.344-.915-2.584v-.073a.52.52 0 0 0-.61-.515H4.51c-.607.103-1.208.24-1.8.411a.312.312 0 1 1-.173-.6c.614-.178 1.237-.32 1.867-.427a1.144 1.144 0 0 1 1.345 1.134v.07c0 1.103.34 1.758.727 2.136.394.386.867.514 1.176.514.745 0 1.21-.241 1.49-.548.287-.313.413-.729.413-1.124v-.006a1.951 1.951 0 0 0-.337-1.136.774.774 0 0 1-.133-.477 1.142 1.142 0 0 1 1.126-1.08c.176-.004.355-.006.536-.006a8.539 8.539 0 0 1 1.565.127h.001a1.144 1.144 0 0 1 .897.827c.026.097.039.196.04.296 0 1.294.386 2.068.834 2.518.453.456.997.607 1.353.607 1.095 0 1.616-.412 1.88-.815a2.03 2.03 0 0 0 .307-1.06c0-.329-.056-.654-.166-.964a1.142 1.142 0 0 1 1.007-1.523c.127-.01.266-.013.41-.013h.004c.865.013 1.726.12 2.568.321a.312.312 0 1 1-.146.608 11.168 11.168 0 0 0-2.43-.304c-.135 0-.256.003-.36.011l-.007.001a.517.517 0 0 0-.457.69c.134.376.203.773.202 1.173h-.312.312c0 .208-.033.827-.41 1.402-.39.599-1.12 1.098-2.402 1.098-.51 0-1.217-.208-1.797-.791-.586-.59-1.016-1.534-1.016-2.959h.313-.313a.538.538 0 0 0-.018-.135l.303-.08-.303.08a.518.518 0 0 0-.188-.279.496.496 0 0 0-.214-.094Zm0 0 .054-.308-.054.308Z"
        clip-rule="evenodd"
      />
      <path
        :stroke="props.color"
        stroke-width=".3"
        d="M12.205 15.867a7.924 7.924 0 0 0-1.451-.117h-.004c-.178 0-.354.002-.527.005h-.003a.517.517 0 0 0-.51.49.15.15 0 0 0 .026.091c.3.441.455.964.445 1.497 0 .519-.165 1.094-.577 1.543-.418.457-1.061.75-1.95.75-.458 0-1.092-.181-1.614-.69-.529-.517-.915-1.344-.915-2.584v-.073a.52.52 0 0 0-.61-.515H4.51c-.607.103-1.208.24-1.8.411a.312.312 0 1 1-.173-.6c.614-.178 1.237-.32 1.867-.427a1.144 1.144 0 0 1 1.345 1.134v.07c0 1.103.34 1.758.727 2.136.394.386.867.514 1.176.514.745 0 1.21-.241 1.49-.548.287-.313.413-.729.413-1.124v-.006a1.951 1.951 0 0 0-.337-1.136.774.774 0 0 1-.133-.477 1.142 1.142 0 0 1 1.126-1.08c.176-.004.355-.006.536-.006a8.539 8.539 0 0 1 1.565.127h.001a1.144 1.144 0 0 1 .897.827c.026.097.039.196.04.296 0 1.294.386 2.068.834 2.518.453.456.997.607 1.353.607 1.095 0 1.616-.412 1.88-.815a2.03 2.03 0 0 0 .307-1.06c0-.329-.056-.654-.166-.964a1.142 1.142 0 0 1 1.007-1.523c.127-.01.266-.013.41-.013h.004c.865.013 1.726.12 2.568.321a.312.312 0 1 1-.146.608 11.168 11.168 0 0 0-2.43-.304c-.135 0-.256.003-.36.011l-.007.001a.517.517 0 0 0-.457.69c.134.376.203.773.202 1.173m-6.045-1.758c.078.014.15.046.213.094h.001c.092.07.159.168.188.28m-.402-.374.054-.308-.054.308Zm6.045 1.758h-.312.312Zm0 0c0 .208-.033.827-.41 1.402-.39.599-1.12 1.098-2.402 1.098-.51 0-1.217-.208-1.797-.791-.586-.59-1.016-1.534-1.016-2.959m0 0h.313-.313Zm0 0a.538.538 0 0 0-.018-.135m0 0 .303-.08-.303.08Z"
      />
      <path
        :fill="props.color"
        fill-rule="evenodd"
        :stroke="props.color"
        stroke-width=".3"
        d="M9.504 10.342C8.223 12.062 7 14.381 7 16.851c0 .684.168 1.03.316 1.201.15.174.305.198.337.198.127 0 .306-.003.45-.066a.323.323 0 0 0 .143-.11.417.417 0 0 0 .06-.245.81.81 0 0 0-.051-.314.792.792 0 0 0-.122-.196l-.034-.041c-.108-.133-.275-.336-.275-.672 0-2.152.81-4.283 1.695-5.983.187-.358.377-.699.565-1.017-.191.23-.386.476-.58.736Zm2.378-3.321A.358.358 0 0 1 12 7a.313.313 0 0 1 .251.498l-.04.055c-.389.523-1.305 1.759-2.137 3.358-.866 1.662-1.625 3.689-1.625 5.695 0 .106.03.144.142.286l.032.04c.067.085.148.196.21.347.062.152.099.33.099.55 0 .237-.06.439-.173.602a.946.946 0 0 1-.408.326c-.271.118-.567.118-.688.118h-.01c-.208 0-.537-.099-.81-.414-.274-.318-.468-.827-.468-1.61 0-2.666 1.313-5.117 2.628-6.882a17.75 17.75 0 0 1 1.848-2.113c.26-.251.485-.45.655-.588.085-.068.16-.124.222-.165a.97.97 0 0 1 .094-.056.49.49 0 0 1 .06-.026Z"
        clip-rule="evenodd"
      />
    </g>
  </svg>
</template>
