<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path
      :transform="`scale(${scale})`"
      :fill="props.color"
      fill-rule="evenodd"
      d="M15.775 2.88a.622.622 0 0 0-.323.398c-.051.275-.002.363.462.832l.434.44-1.028 1.027-1.027 1.027-1.012-1.007c-1.091-1.088-1.096-1.092-1.412-1.033-.176.033-.39.248-.423.424-.053.283-.007.368.46.84l.433.439-3.1 3.104c-2.137 2.14-3.126 3.153-3.184 3.265-.07.136-.084.214-.084.5 0 .416.058.545.406.905l.233.242-.645.652c-.539.545-.663.692-.755.886-.271.574-.226 1.174.128 1.711l.125.191-1.291 1.295c-1.396 1.4-1.376 1.373-1.315 1.695.033.176.247.39.423.423.322.06.295.081 1.698-1.318 1.145-1.142 1.305-1.29 1.36-1.25.385.272.697.38 1.1.38.31.001.569-.066.842-.22.088-.05.446-.373.795-.718l.634-.628.243.234c.36.347.49.405.904.405.287 0 .365-.013.501-.083.111-.058 1.125-1.047 3.265-3.184l3.104-3.1.44.434c.47.466.556.512.84.46.175-.034.39-.248.422-.424.06-.316.056-.32-1.032-1.412L17.388 9.7l1.028-1.027 1.028-1.028.439.434c.472.467.556.513.84.46.176-.033.39-.247.423-.423.063-.333.18-.197-2.41-2.794-1.378-1.382-2.435-2.413-2.502-2.442a.588.588 0 0 0-.459 0Zm2.132 3.224.733.734-1.029 1.029-1.029 1.029-.742-.743-.742-.742 1.019-1.02c.56-.561 1.028-1.02 1.038-1.02.01 0 .348.33.752.733Zm-2.864 2.863 1.879 1.88-.742.741-.742.742-1.012-1.008c-1.091-1.087-1.096-1.09-1.412-1.031-.176.032-.39.247-.423.423-.06.316-.056.32 1.032 1.412l1.008 1.011-.742.742-.742.742-1.011-1.008c-1.092-1.088-1.096-1.091-1.412-1.032-.176.033-.39.247-.424.423-.059.316-.055.32 1.033 1.412l1.008 1.012-.743.742-.742.742-1.888-1.888-1.887-1.887 3.024-3.024a363.043 363.043 0 0 1 3.042-3.025c.01 0 .863.846 1.896 1.88Zm-6.87 6.872.733.734-.59.587c-.664.659-.76.716-1.047.631-.22-.064-1.003-.847-1.068-1.067-.084-.287-.026-.384.622-1.037.317-.32.586-.582.597-.582.01 0 .35.33.752.734Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
