<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path
      :transform="`scale(${scale})`"
      :fill="props.color"
      fill-rule="evenodd"
      d="M4.188 5.82a.646.646 0 0 0-.476.97l1.616 2.827a11.409 11.409 0 0 0-.734.675c-1.521 1.52-2.209 3.041-2.234 3.103a.646.646 0 0 0 0 .525c.029.063.713 1.58 2.234 3.102 2.027 2.026 4.587 3.097 7.404 3.097 2.818 0 5.378-1.071 7.405-3.097 1.52-1.521 2.205-3.038 2.233-3.102a.646.646 0 0 0 0-.525c-.02-.048-.405-.902-1.229-1.963a.646.646 0 0 0-.122-.2 12.4 12.4 0 0 0-1.559-1.615l1.616-2.828a.648.648 0 0 0-.96-.837.649.649 0 0 0-.166.191l-1.53 2.684a9.956 9.956 0 0 0-2.913-1.348l.476-2.845a.647.647 0 1 0-1.276-.212l-.466 2.793a10.749 10.749 0 0 0-2.963 0l-.466-2.793a.646.646 0 1 0-1.272.212l.474 2.845a9.954 9.954 0 0 0-2.915 1.346L4.83 6.143a.646.646 0 0 0-.642-.322Zm.469 6.348a.646.646 0 0 0 .117-.128c.393-.485.872-.998 1.442-1.48 1.658-1.377 3.6-2.074 5.782-2.074 2.487 0 4.66.904 6.457 2.686.74.734 1.369 1.57 1.868 2.484-.582 1.088-3.12 5.17-8.325 5.17-2.486 0-4.658-.904-6.456-2.686a10.784 10.784 0 0 1-1.864-2.484c.285-.523.613-1.02.979-1.488Zm5.187-1.736a3.877 3.877 0 1 1 4.308 6.447 3.877 3.877 0 0 1-4.308-6.447Zm.718 5.374a2.585 2.585 0 1 0 2.872-4.298 2.585 2.585 0 0 0-2.872 4.298Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
