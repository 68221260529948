<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      clip-path="url(#clip0_15912_73016)"
      :transform="`scale(${scale})`"
      :fill="color"
      :stroke="color"
      stroke-width="0.3"
    >
      <path
        d="M18.506 6.257a.39.39 0 0 0-.39.39 1.978 1.978 0 0 1-.403 1.18c-.536.733-1.555 1.23-2.721 1.23h-.665a.39.39 0 0 0 0 .779h.665c.993 0 1.901-.305 2.591-.806l-.366 5.301a.39.39 0 1 0 .777.054l.43-6.22c.301-.452.472-.97.472-1.518a.39.39 0 0 0-.39-.39Z"
      />
      <path
        d="M17.916 2.039H6.084A4.088 4.088 0 0 0 2 6.123v1.97c0 .946.173 1.873.516 2.756a6.85 6.85 0 0 1 .367 3.614l-.15.887a7.623 7.623 0 0 0-.048 2.216l.512 4.092a.39.39 0 0 0 .387.341h16.782a.39.39 0 0 0 .387-.34l.533-4.112a7.425 7.425 0 0 0-.049-2.236l-.147-.841a6.654 6.654 0 0 1 .378-3.619A7.405 7.405 0 0 0 22 8.088V6.123a4.088 4.088 0 0 0-4.084-4.084Zm3.304 6.05c0 .85-.16 1.683-.476 2.473a7.434 7.434 0 0 0-.422 4.041l.147.842c.116.662.13 1.336.044 2.002l-.49 3.773h-1.78l-.016-.105a28.12 28.12 0 0 1-.324-3.621.39.39 0 0 0-.78.018 28.89 28.89 0 0 0 .331 3.707H15.21l1.053-1.315a.39.39 0 0 0-.61-.488L14.21 21.22h-1.88v-1.695c.576.148 1.11.086 1.593-.187a.39.39 0 1 0-.384-.68c-.352.2-.75.217-1.209.052v-.94c.576.148 1.11.086 1.593-.187a.39.39 0 1 0-.384-.678c-.352.199-.75.216-1.209.051v-1.713a.39.39 0 1 0-.78 0v1.74c-.446.13-.804.136-1.218-.084a.39.39 0 0 0-.365.69c.367.194.71.265 1.032.265.192 0 .375-.026.551-.066v.95c-.446.13-.804.135-1.218-.084a.39.39 0 0 0-.365.689c.367.194.71.265 1.032.265.192 0 .375-.026.551-.065v1.677H9.595l-1.442-1.803a.39.39 0 0 0-.61.488l1.053 1.316H6.433c.364-2.263.458-4.564.277-6.85l-.42-5.317c.685.488 1.58.783 2.559.783h.704a.39.39 0 0 0 0-.78h-.704c-1.152 0-2.16-.483-2.701-1.2a1.986 1.986 0 0 1-.424-1.209.39.39 0 0 0-.78 0c0 .563.18 1.093.496 1.553l.493 6.232c.179 2.265.08 4.547-.291 6.787H3.928l-.469-3.75a6.845 6.845 0 0 1 .043-1.989l.15-.887a7.63 7.63 0 0 0-.41-4.026 6.82 6.82 0 0 1-.462-2.474v-1.97a3.308 3.308 0 0 1 3.304-3.304h11.832a3.308 3.308 0 0 1 3.304 3.304v1.965Z"
      />
      <path
        d="M8.68 7.692a.383.383 0 0 0-.284-.233.388.388 0 0 0-.436.531.394.394 0 0 0 .283.233.386.386 0 0 0 .293-.058.4.4 0 0 0 .166-.248.387.387 0 0 0-.023-.225ZM15.162 7.99a.392.392 0 0 0 .36.24.394.394 0 0 0 .383-.313.376.376 0 0 0-.058-.293.394.394 0 0 0-.6-.06.368.368 0 0 0-.085.128.384.384 0 0 0 0 .298ZM11.94 12.94a3.527 3.527 0 0 0-3.522 3.523.39.39 0 0 0 .78 0 2.746 2.746 0 0 1 2.743-2.742 2.746 2.746 0 0 1 2.742 2.742.39.39 0 0 0 .78 0 3.527 3.527 0 0 0-3.522-3.522ZM17.916 15.769a.374.374 0 0 0-.144-.175.392.392 0 0 0-.599.248.387.387 0 0 0 .107.351.385.385 0 0 0 .425.085.391.391 0 0 0 .233-.436.368.368 0 0 0-.022-.073Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_15912_73016">
        <rect :width="DEFAULT_SIZE" :height="DEFAULT_SIZE" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
