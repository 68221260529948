<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :stroke="props.color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    >
      <path
        d="M15.417 15.338C17.47 13.942 19 11.792 19 9.143a7.143 7.143 0 0 0-14.286 0v9.222C4.715 20.34 6.31 22 8.286 22c1.976 0 2.984-1.158 3.45-1.786.66-.884 1.863-3.64 3.68-4.876Z"
      />
      <path
        d="M7.57 14.143V8.786c0-2.161 1.929-3.93 4.286-3.93 2.357 0 4.286 1.769 4.286 3.93"
      />
      <path
        d="M7.57 11.242c1.116-.804 3.564-.67 3.564-.67 1.16 0 1.838 1.313 1.16 2.259 0 0-1.645 1.893-1.868 2.74"
      />
    </g>
  </svg>
</template>
