<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g :transform="`scale(${scale})`" :fill="props.color">
      <g :stroke="props.color" stroke-width=".3">
        <path
          d="M22.564 16.672c-.682.147-1.775-.567-3.966-2.225a21.252 21.252 0 0 1-1.257-1.072.316.316 0 1 0-.424.47c.57.514.996.876 1.3 1.106 2.31 1.749 3.506 2.55 4.481 2.339a.81.81 0 0 1 .16-.021.41.41 0 0 1-.067.131c-.409.252-1.597.784-2.387.347a12.335 12.335 0 0 1-.523-.311c-.77-.476-1.497-.926-2.663-.278-.636.353-1.2.263-1.456.029a.377.377 0 0 1-.096-.463.318.318 0 0 0 .018-.053 2.714 2.714 0 0 0-.104-1.41c-.426-1.243-1.598-1.862-2.507-2.162-2.101-.695-4.962-1.792-5.54-3.282-.19-.494-.458-.898-.793-1.199a1.113 1.113 0 0 0-.898-.273 1.131 1.131 0 0 0-.803.516c-.549.866-1.653 2.41-2.882 3.015a.316.316 0 0 0 .28.568c1.367-.673 2.552-2.322 3.136-3.245a.5.5 0 0 1 .355-.228.475.475 0 0 1 .39.118c.26.234.47.556.626.956.276.713.921 1.365 2.003 1.998v.002c.741 4.854 1.955 8.575 2.7 9.369.658.7 1.077.915 1.302 1.031l.012.007c-.081.034-.372.104-1.128-.163-1.239-.437-1.738-1.33-2.178-2.118-.066-.12-.129-.232-.194-.343-.262-.447-.9-.71-1.576-.987-.495-.204-1.173-.483-1.259-.741-.007-.023-.027-.084.052-.215l.088-.145c.684-1.126 1.46-2.403.895-3.6-.3-.636-1.115-2.04-1.746-3.104a.316.316 0 1 0-.544.322c.623 1.054 1.428 2.438 1.718 3.053.42.888-.233 1.962-.864 3l-.088.147c-.153.252-.19.5-.111.74.176.534.876.823 1.618 1.128.524.215 1.117.46 1.271.722.062.106.123.215.188.331.47.842 1.055 1.89 2.519 2.407.441.156.814.233 1.117.233.329 0 .576-.091.737-.274a.62.62 0 0 0 .106-.67c-.077-.154-.206-.221-.343-.292-.205-.105-.547-.282-1.13-.902-.56-.597-1.707-3.879-2.463-8.563.86.429 1.928.851 3.229 1.281 2.5.828 2.243 2.577 2.2 2.8-.17.412-.07.852.26 1.155.431.394 1.276.565 2.19.057.842-.468 1.287-.193 2.024.263.172.106.35.217.549.326.326.18.677.248 1.021.248 1.014 0 1.967-.586 2.02-.619.225-.14.458-.598.318-.942-.07-.175-.281-.447-.893-.315Z"
        />
        <path
          d="M6.257 3.701c.332.2 2.467 2.279 4.532 4.289 1.454 1.416 3.103 3.021 4.486 4.329a.315.315 0 0 0 .447-.013.316.316 0 0 0-.012-.447 458.468 458.468 0 0 1-4.48-4.322c-.883-.86-1.643-1.6-2.281-2.215a.316.316 0 0 0 .009-.095c-.099-1.846-.62-3.123-1.547-3.796-.813-.59-1.909-.706-3.255-.345a.316.316 0 1 0 .164.61c1.153-.309 2.068-.226 2.72.247.671.487 1.087 1.406 1.238 2.736-.935-.892-1.507-1.411-1.71-1.528-.323-.187-.968-.185-3.297 1.24A46.663 46.663 0 0 0 .867 5.977a.316.316 0 1 0 .367.515c.011-.008 1.108-.789 2.298-1.521 2.194-1.35 2.667-1.287 2.725-1.27Z"
        />
        <path
          d="M.817 3.378c.064 0 .13-.02.185-.06.007-.005.662-.479 1.572-.936a.316.316 0 0 0-.284-.566c-.96.483-1.632.97-1.66.99a.316.316 0 0 0 .187.572Z"
        />
      </g>

      <path
        d="m21.11 4.218-.64 1.41a.12.12 0 0 1-.06.06l-1.169.53a.12.12 0 0 0 0 .22l1.169.53a.12.12 0 0 1 .06.06L21 8.196a.12.12 0 0 0 .22 0l.53-1.168a.12.12 0 0 1 .06-.06l1.169-.53a.12.12 0 0 0 0-.22l-1.21-.55"
      />
    </g>
  </svg>
</template>
