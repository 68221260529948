<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".3"
      d="M20.404 5.083a.712.712 0 0 0-.536-.208c-.923 0-1.606 1.507-2.266 2.964-.17.378-.381.843-.558 1.169.039-.516.168-1.308.271-1.94.366-2.241.554-3.642.081-4.198a.682.682 0 0 0-.527-.245c-1.51 0-1.88 2.139-2.206 4.025-.102.588-.258 1.487-.427 1.906-.127-.617-.08-1.876-.048-2.77.079-2.126.087-3.458-.46-4.024a.826.826 0 0 0-.607-.262c-1.485 0-1.59 2.822-1.681 5.313-.022.593-.048 1.284-.1 1.735-.207-.491-.457-1.327-.639-1.939-.616-2.066-1-3.234-1.703-3.234-.426 0-.675.195-.808.358-.557.678-.258 2.175.12 4.07.376 1.88.797 3.99.447 5.545-.252-.202-.605-.565-.886-.852-.985-1.008-2.094-2.152-3.204-2.01-.5.063-.917.384-1.243.953a.376.376 0 0 0 .102.488c1.38 1.022 2.094 2.366 2.784 3.667.874 1.644 1.776 3.345 3.994 4.233.07.13.192.602.192 2.298a.375.375 0 1 0 .75 0c0-2.045-.154-2.796-.613-2.974-2-.781-2.806-2.3-3.66-3.91-.682-1.285-1.386-2.612-2.716-3.697.155-.188.321-.291.505-.315.74-.087 1.796.996 2.572 1.791.79.808 1.222 1.23 1.664 1.23.16 0 .303-.102.355-.254.605-1.77.12-4.198-.307-6.34-.285-1.427-.608-3.043-.277-3.447.03-.037.077-.075.187-.082.293.24.75 1.772 1.026 2.697.586 1.96.87 2.827 1.465 2.827.638 0 .67-.88.742-2.81.055-1.49.17-4.591.932-4.591.023 0 .04.002.068.032.364.378.299 2.168.25 3.475-.072 1.95-.09 3.17.4 3.66a.354.354 0 0 0 .275.11c.816-.022 1.024-1.226 1.288-2.75.259-1.499.58-3.36 1.438-3.402.257.374-.082 2.447-.265 3.572-.325 1.993-.41 2.719-.08 3.04.11.106.257.154.407.136.48-.026.776-.635 1.384-1.975.36-.798 1.102-2.435 1.577-2.518.02.11.01.342.005.475-.042 1.026-.686 2.875-1.204 4.36-.441 1.264-.67 1.94-.67 2.284 0 2.987-.47 5.126-1.434 6.539-.547.8-.452 2.65-.44 2.859.013.207.193.37.397.352a.374.374 0 0 0 .352-.397c-.041-.689.007-1.947.31-2.391 1.053-1.541 1.565-3.818 1.565-6.961 0-.234.333-1.192.628-2.037.534-1.532 1.198-3.44 1.245-4.576.017-.41.033-.797-.213-1.054Z"
    />
  </svg>
</template>
