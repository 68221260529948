<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g :transform="`scale(${scale})`" :fill="props.color">
      <g :fill="props.color" :stroke="props.color" stroke-width=".3">
        <path
          d="M19.77 12.432c.77-2.277.896-4.244.373-5.85-.454-1.393-1.29-2.15-1.747-2.478a.132.132 0 0 1-.053-.134c.183-.862-.422-2.358-.903-2.907l-.04-.046c-.237-.273-.596-.685-1.135-.444-.782.349-2.23.654-3.762.977-1.563.33-3.179.67-4.246 1.097-1.327.53-2.341 1.18-3.099 1.984-.843.895-1.353 1.959-1.558 3.251-.253 1.592.34 3.494.674 4.393a1.17 1.17 0 0 0-.401.337c-.405.526-.28 1.822.256 2.666.463.738 1.203 1.088 2.026 1.002.39 1.818 1.368 3.162 2.299 4.078L8.1 23.12a.337.337 0 0 0 .668.086l.294-2.298c.323.269.621.48.862.637.42.273.91.418 1.416.418h1.429c.507 0 .996-.145 1.416-.418a9.42 9.42 0 0 0 .862-.637l.294 2.298a.337.337 0 1 0 .668-.086l-.353-2.762c.93-.916 1.908-2.26 2.3-4.078.744.08 1.428-.223 1.828-.868.563-.885.555-2.28.154-2.8a1.236 1.236 0 0 0-.17-.18ZM4.265 7.988c.35-2.2 1.697-3.698 4.242-4.715 1.013-.405 2.6-.74 4.135-1.063 1.568-.331 3.05-.644 3.898-1.022.06-.027.106-.01.352.272l.041.047c.368.42.878 1.726.75 2.323a.806.806 0 0 0 .32.822c.391.28 1.105.93 1.499 2.139.473 1.451.355 3.255-.351 5.366a1.706 1.706 0 0 0-.655.047c.02-.2.03-.403.03-.607.006-3.156-2.318-5-6.376-5.058-3.997-.112-6.963 1.847-6.525 5.765a2.22 2.22 0 0 0-.68-.157c-.272-.718-.92-2.646-.68-4.16Zm.433 6.929c-.476-.749-.435-1.707-.291-1.895.357-.464 1.15-.015 1.283.065l.09.082c.062.242.136.48.225.714a8.445 8.445 0 0 0 .036 1.73c-.572.04-1.023-.193-1.343-.696Zm9.12 6.063c-.31.202-.672.309-1.048.309H11.34c-.376 0-.738-.107-1.048-.309-1.205-.785-3.969-3.05-3.61-7.117a.337.337 0 0 0-.022-.153 5.777 5.777 0 0 1-.402-2.126c.03-3.251 2.607-4.396 5.881-4.371 4.977.071 5.715 2.777 5.712 4.383a5.775 5.775 0 0 1-.402 2.114.337.337 0 0 0-.022.153c.358 4.067-2.405 6.332-3.61 7.117Zm5.399-5.929c-.264.415-.64.6-1.147.564a8.44 8.44 0 0 0 .035-1.732c.116-.303.207-.613.275-.928.32-.142.776-.256 1.025.067.18.234.268 1.31-.189 2.03Z"
        />
        <path
          d="M9.643 13.092c-.465.251-.91.016-.934.003a.337.337 0 0 0-.332.586c.02.012.357.2.815.2.234 0 .5-.05.771-.196a.337.337 0 0 0-.32-.593ZM9.643 11.75c-.465-.252-.91-.017-.934-.004a.337.337 0 0 1-.332-.586c.02-.012.357-.2.815-.2.234 0 .5.05.771.196a.337.337 0 0 1-.32.593ZM15.526 11.75c-.465-.252-.91-.017-.934-.004a.337.337 0 0 1-.332-.586c.02-.012.357-.2.815-.2.234 0 .5.05.771.196a.337.337 0 1 1-.32.593ZM15.526 13.092c-.465.252-.91.016-.934.003a.337.337 0 0 0-.332.586c.02.012.357.2.815.2.234 0 .5-.05.77-.196a.337.337 0 1 0-.32-.593ZM12.102 13.78a.337.337 0 0 0-.336.337v1.323a.337.337 0 1 0 .673 0v-1.323a.337.337 0 0 0-.337-.337ZM12.803 17.718a2.383 2.383 0 0 1-1.38.007.337.337 0 0 0-.236.63c.022.01.381.142.909.142.265 0 .574-.034.903-.134a.337.337 0 0 0-.196-.645Z"
        />
      </g>
      <path
        d="m20 18-.597 1.314a.178.178 0 0 1-.089.089l-.958.435a.178.178 0 0 0 0 .324l.958.435c.04.018.07.05.089.089l.435.958c.063.139.26.139.324 0l.435-.958c.018-.04.05-.07.089-.089l.958-.435a.178.178 0 0 0 0-.324l-1.019-.463"
      />
    </g>
  </svg>
</template>
