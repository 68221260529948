<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".2"
    >
      <path
        d="M20.328 15.173h-1.983c-1.72 0-3.173-1.462-3.173-2.38v-2.587c.38-.35.67-.737.799-1.131.242-.744.376-3.129.39-3.398a.397.397 0 0 0-.792-.043c-.037.704-.174 2.649-.353 3.195-.093.286-.325.578-.63.847a.387.387 0 0 0-.114.096c-.717.585-1.766 1.039-2.472 1.039-.71 0-1.765-.459-2.483-1.048-.02-.023-.046-.04-.072-.058-.321-.277-.565-.58-.662-.876-.178-.546-.315-2.492-.352-3.195a.396.396 0 1 0-.792.042c.014.27.148 2.655.39 3.399.129.394.42.782.799 1.13v2.588c0 .918-1.452 2.38-3.173 2.38H3.672C1.886 15.173.5 16.878.5 18.345a.396.396 0 1 0 .793 0c0-1.071 1.098-2.38 2.38-2.38h1.982c2.136 0 3.966-1.744 3.966-3.172v-1.99c.777.483 1.68.8 2.379.8.698 0 1.602-.317 2.38-.8v1.99c0 1.428 1.829 3.173 3.965 3.173h1.983c1.28 0 2.379 1.308 2.379 2.38a.396.396 0 1 0 .793 0c0-1.468-1.386-3.173-3.172-3.173Z"
      />
      <path
        d="M17.554 16.363c-2.215 0-4.242.111-4.711 1.455a.397.397 0 0 0 .749.261c.297-.852 2.201-.923 3.962-.923a.396.396 0 1 0 0-.793ZM6.451 16.363a.396.396 0 1 0 0 .793c1.761 0 3.666.071 3.963.924a.397.397 0 0 0 .75-.262c-.47-1.344-2.497-1.455-4.713-1.455ZM13.061 16.31a.396.396 0 0 0 .504-.247l.396-1.159a.397.397 0 1 0-.75-.256l-.397 1.158c-.07.207.04.433.247.504ZM10.788 14.648a.397.397 0 1 0-.75.256l.396 1.159a.397.397 0 1 0 .75-.257l-.396-1.158Z"
      />
    </g>
  </svg>
</template>
