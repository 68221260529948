<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => {
  return props.size / DEFAULT_SIZE
})
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".3"
      clip-path="url(#a)"
    >
      <path
        d="m14.957 7.547 5.418 1.529a.293.293 0 0 0 .232-.532l-4.533-2.766a4.701 4.701 0 0 0-3.056-.646l-6.615.876a.293.293 0 0 0 .077.58l6.615-.875a4.114 4.114 0 0 1 2.674.565l2.712 1.655-3.365-.95a4.404 4.404 0 0 0-2.211-.045L2.585 9.396V8.9c0-1.024.767-1.898 1.782-2.032l.919-.122a.293.293 0 0 0-.077-.58l-.919.12A2.644 2.644 0 0 0 2 8.902v.865a.293.293 0 0 0 .36.286l10.68-2.544a3.817 3.817 0 0 1 1.917.04ZM18.797 17.084c-1.554.5-2.692-.243-3.195-1.03a.293.293 0 1 0-.494.314 3.174 3.174 0 0 0 2.702 1.463 3.81 3.81 0 0 0 1.167-.19.293.293 0 0 0-.18-.557Z"
      />
      <path
        d="M21.772 12.693a.293.293 0 0 0-.35.222c-.262 1.173-1.15 1.432-1.655 1.482-.911.09-1.856-.344-2.197-.999.253-.254.493-.521.72-.803a.293.293 0 0 0-.456-.368 9.561 9.561 0 0 1-6.68 3.54A9.626 9.626 0 0 1 4.95 14.14a1.666 1.666 0 0 0-1.075-.279l-.22.019a.293.293 0 0 0 .05.583l.219-.018c.25-.02.496.043.696.179.754.513 1.571.92 2.427 1.212-.24.418-.623.518-.64.522a.293.293 0 1 0 .13.571c.031-.007.72-.17 1.08-.917.248.07.499.129.752.179-.088.668-.58 1.203-.584 1.208a.293.293 0 1 0 .427.401c.027-.028.614-.663.734-1.511.244.034.488.059.734.075a3.666 3.666 0 0 1-.448 1.673.293.293 0 0 0 .5.307c.022-.036.514-.85.534-1.958l.091.001c.213 0 .427-.007.64-.02v2.248a.293.293 0 0 0 .586 0v-2.303c.22-.026.438-.059.654-.1-.002.552.112 1.777 1.1 2.504a.292.292 0 0 0 .41-.063.293.293 0 0 0-.062-.41c-.862-.632-.873-1.825-.858-2.158.211-.053.42-.112.627-.177.092.697.473 1.73 1.18 2.31.368.302.797.457 1.258.457.14 0 .285-.015.43-.044a.293.293 0 1 0-.114-.575c-.458.092-.851-.004-1.203-.292-.64-.524-.943-1.544-.983-2.055a10.163 10.163 0 0 0 1.987-1.029c.252.687.858 1.31 1.63 1.65.301.133.754.276 1.309.276.567 0 1.241-.15 1.965-.617a.293.293 0 0 0-.318-.493c-1.198.774-2.218.519-2.72.298-.68-.3-1.213-.88-1.364-1.47.214-.162.422-.333.623-.512.454.712 1.404 1.183 2.39 1.183.1 0 .2-.005.3-.015 1.129-.112 1.92-.818 2.17-1.937a.293.293 0 0 0-.222-.35Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M2 2h20v20H2z" /></clipPath>
    </defs>
  </svg>
</template>
