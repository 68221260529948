<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".3"
      clip-path="url(#clip0_15912_73070)"
    >
      <path
        d="M14.872 6.336a.3.3 0 0 0 .292-.525l-.206-.115a1.19 1.19 0 0 1-.587-.794l-.182-.864a.302.302 0 0 0-.465-.185.3.3 0 0 0-.124.31l.183.863a1.793 1.793 0 0 0 .883 1.196l.206.114Z"
      />
      <path
        d="m21.847 20.332-1.49-.25a28.184 28.184 0 0 0-2.692-1.4 2.04 2.04 0 0 1-1.205-1.862l.033-11.951a2.97 2.97 0 0 0-2.966-2.967H2.203a.3.3 0 1 0 0 .602h11.324a2.367 2.367 0 0 1 2.365 2.363l-.033 11.95a2.642 2.642 0 0 0 1.559 2.412c.917.417 1.81.884 2.676 1.397a.3.3 0 0 0 .107.04l1.285.215c-.052.556-.287.604-.604.604a3.738 3.738 0 0 1-.8-.137.303.303 0 0 0-.137-.006c-.397.078-.8.13-1.204.154a8.152 8.152 0 0 1-1.946-.25 2.808 2.808 0 0 0-1.63.075c-.325.116-.67.175-1.015.175h-.564a.992.992 0 0 1-.99-.99c.006-.416.097-.827.267-1.207.345-.8.492-1.672.428-2.542a44.401 44.401 0 0 0-.186-1.9 7.81 7.81 0 0 0-.807-2.698c-1.309-2.595-.022-5.284-.009-5.31a.3.3 0 0 0-.397-.406c-3.26 1.528-9.666 2.444-9.73 2.453a.3.3 0 1 0 .083.596c.25-.034 5.785-.827 9.233-2.214-.34 1.04-.755 3.094.283 5.152a7.34 7.34 0 0 1 .746 2.484c.07.581.132 1.217.184 1.89a4.825 4.825 0 0 1-.382 2.259c-.201.454-.309.946-.315 1.443a1.593 1.593 0 0 0 1.592 1.592h.563c.414 0 .826-.07 1.217-.21a2.203 2.203 0 0 1 1.281-.059 8.777 8.777 0 0 0 2.093.269 9.01 9.01 0 0 0 1.248-.152c.292.08.591.128.894.142 1.215 0 1.215-1.098 1.215-1.459a.301.301 0 0 0-.25-.297Z"
      />
      <path
        d="M14.271 17.824a.3.3 0 0 0-.418.08c-.206.302-.431 1.008.183 1.512a.3.3 0 1 0 .381-.464c-.328-.27-.112-.639-.065-.71a.3.3 0 0 0-.08-.418Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_15912_73070">
        <rect width="22" height="22" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
</template>
