<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => {
  return props.size / DEFAULT_SIZE
})
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path
      :transform="`scale(${scale})`"
      :fill="props.color"
      fill-rule="evenodd"
      :stroke="props.color"
      stroke-width=".5"
      d="M6.035 4.114c-.102.102-.12.166-.12.43 0 .876-.355 1.472-1.429 2.398-1.606 1.384-1.96 3.855-.816 5.683.179.285.427.579.764.906.55.533.671.726.879 1.4.124.406.132.488.133 1.385 0 1.068-.065 1.482-.396 2.517-.11.345-.165.595-.146.67.042.166.242.267.412.207.167-.058.343-.464.55-1.265.352-1.367.381-2.756.079-3.759-.066-.218-.065-.233.01-.204.479.184.843.241 1.525.238.827-.005 1.239-.094 1.903-.411.546-.261.932-.542 1.356-.989.601-.633.721-.95.432-1.14-.19-.125-.328-.054-.598.31-.758 1.019-1.853 1.577-3.093 1.575-1.715-.001-3.152-1.073-3.675-2.738-.099-.314-.115-.465-.114-1.074.002-.668.01-.735.157-1.157.284-.82.606-1.253 1.576-2.119.718-.64 1.094-1.385 1.159-2.294.03-.422.027-.439-.097-.562-.159-.16-.297-.161-.451-.007Zm11.484.007c-.123.122-.126.142-.1.555.067 1.029.49 1.768 1.483 2.598.473.396.797.808 1.052 1.34.247.516.337.875.37 1.468.063 1.124-.295 2.044-1.11 2.86-.774.774-1.608 1.122-2.688 1.123-1.233.002-2.338-.56-3.089-1.569-.275-.37-.412-.442-.603-.317-.29.19-.169.508.433 1.141.424.447.81.728 1.355.989.677.324 1.075.408 1.928.408.649 0 .781-.014 1.149-.125.228-.07.422-.12.431-.11.009.008-.02.133-.063.277-.203.674-.272 1.71-.17 2.536.12.963.456 2.2.641 2.358.134.114.292.108.423-.014.14-.13.136-.217-.037-.757-.295-.921-.364-1.376-.367-2.42-.002-1.106.047-1.368.39-2.078.181-.372.291-.519.718-.955.945-.967 1.326-1.871 1.326-3.152 0-1.353-.509-2.506-1.472-3.335-1.074-.926-1.429-1.522-1.429-2.398 0-.264-.018-.328-.12-.43-.154-.154-.292-.152-.451.007Zm-12.3 6.918a.58.58 0 0 0-.164.2c-.108.21-.055.443.138.605.127.107.189.126.342.105.224-.03.325-.105.408-.303a.466.466 0 0 0-.438-.658c-.11 0-.24.024-.287.051Zm12.975.05c-.163.151-.214.323-.152.51.07.214.2.32.426.35.375.05.659-.369.482-.71-.098-.189-.213-.253-.456-.253a.427.427 0 0 0-.3.103Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
