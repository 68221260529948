<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".3"
    >
      <path
        d="M19.557 7.446c-.704-1.42-1.37-2.76-1.37-4.094A.352.352 0 0 0 17.836 3H6.164a.352.352 0 0 0-.351.352c0 1.334-.666 2.675-1.37 4.094C3.733 8.876 3 10.354 3 11.853c0 1.507.362 3.018.713 4.48.34 1.421.693 2.891.693 4.315 0 .195.158.352.352.352h6.187a.352.352 0 0 0 .352-.352c0-.564.023-1.137.046-1.691.06-1.442.12-2.93-.23-4.398.37-.33.673-.734.887-1.189.214.454.518.858.887 1.19-.35 1.467-.29 2.955-.23 4.396.023.555.046 1.128.046 1.692 0 .195.158.352.352.352h6.187a.352.352 0 0 0 .352-.352c0-1.424.352-2.894.693-4.315.35-1.462.713-2.973.713-4.48 0-1.5-.734-2.978-1.443-4.407Zm.046 8.723c-.323 1.347-.656 2.737-.706 4.128h-5.493a51.502 51.502 0 0 0-.045-1.37c-.056-1.354-.109-2.642.149-3.917a3.69 3.69 0 0 0 1.854.497.352.352 0 0 0 0-.703c-1.66 0-3.01-1.35-3.01-3.01V9.937a.352.352 0 0 0-.704 0v1.857c0 1.66-1.35 3.01-3.01 3.01a.352.352 0 0 0 0 .703 3.69 3.69 0 0 0 1.854-.497c.258 1.275.205 2.563.149 3.918-.019.447-.038.907-.045 1.369H5.103c-.05-1.39-.383-2.78-.706-4.128-.341-1.422-.694-2.892-.694-4.316 0-1.335.665-2.675 1.37-4.094.654-1.318 1.328-2.677 1.43-4.056h10.994c.102 1.379.776 2.738 1.43 4.056.705 1.419 1.37 2.76 1.37 4.094 0 1.424-.353 2.894-.694 4.316Z"
      />
      <path d="M7.07 14.588a.351.351 0 1 0 0 .703.351.351 0 0 0 0-.703Z" />
    </g>
  </svg>
</template>
