<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => {
  return props.size / DEFAULT_SIZE
})
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".4"
    >
      <path
        d="M21.05 10.256c-1.1.674-2.242 1.28-3.418 1.812a16.83 16.83 0 0 1-1.952.656c-1.38.407-2.945.869-4.273 1.746a.275.275 0 0 1-.381-.08c-.006-.01-.656-1.016-1.97-2.702-.663-.85-1.182-1.848-1.683-2.812C6.256 6.726 5.29 4.87 3.093 4.87a.273.273 0 0 1 0-.547c2.53 0 3.666 2.186 4.765 4.3.49.943.997 1.919 1.628 2.73a47.97 47.97 0 0 1 1.85 2.514c1.347-.829 2.915-1.291 4.19-1.667a16.12 16.12 0 0 0 1.884-.631 30.387 30.387 0 0 0 3.349-1.776.273.273 0 1 1 .291.463Z"
      />
      <path
        d="M2.948 11.076a.275.275 0 0 1 .304.008c.03.021.054.048.073.078a36.443 36.443 0 0 0 6.528 7.687 1.132 1.132 0 0 0 1.167.199c4.869-1.999 9.67-5.724 9.718-5.761a.274.274 0 0 1 .336.432c-.048.037-4.906 3.807-9.846 5.835a1.686 1.686 0 0 1-1.736-.293 36.997 36.997 0 0 1-6.63-7.808.274.274 0 0 1 .086-.377Z"
      />
      <path
        d="M9.956 17.234c.186.223.396.425.625.603a.045.045 0 0 0 .04.008c.235-.074.464-.163.687-.267a.272.272 0 0 1 .395.234.273.273 0 0 1-.163.261 6.133 6.133 0 0 1-.755.294.585.585 0 0 1-.537-.097 4.604 4.604 0 0 1-.72-.694.274.274 0 0 1 .428-.342Z"
      />
    </g>
  </svg>
</template>
