<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".2"
    >
      <path
        d="M14.89 4.91A3.908 3.908 0 0 0 11.112 2c-1.405 0-2.71.75-3.408 1.959L5.233 8.24l-.001.002-2.473 4.282a3.927 3.927 0 0 0 1.436 5.36 3.936 3.936 0 0 0 5.185-1.165l5.12-8.837a3.887 3.887 0 0 0 .39-2.972ZM8.713 16.311a3.154 3.154 0 0 1-4.128.896 3.145 3.145 0 0 1-1.15-4.292L5.713 8.97l.846.489 4.59 2.65-2.435 4.202Zm5.11-8.82-2.284 3.941-4.252-2.454L8.38 7.085a.39.39 0 0 0-.677-.39L6.611 8.586l-.507-.293L8.38 4.349a3.158 3.158 0 0 1 2.731-1.568 3.126 3.126 0 0 1 3.022 2.331 3.111 3.111 0 0 1-.31 2.379ZM20.06 15.391c-.954-.272-2.212-.422-3.543-.422-1.33 0-2.588.15-3.541.422-1.134.324-1.709.78-1.71 1.355V20.22c0 .578.574 1.035 1.707 1.358.952.272 2.21.422 3.544.422s2.593-.15 3.545-.422c1.133-.323 1.707-.78 1.707-1.358v-3.472c0-.576-.575-1.033-1.71-1.357Zm-6.87.752c.886-.254 2.068-.393 3.327-.393 1.26 0 2.442.14 3.328.393.942.269 1.142.558 1.143.605v.001c-.002.048-.203.336-1.143.605-.886.253-2.068.393-3.328.393-1.26 0-2.44-.14-3.327-.393-.944-.27-1.143-.56-1.143-.606 0-.046.199-.336 1.143-.605Zm7.798 4.077c0 .056-.203.34-1.14.607-.885.253-2.067.392-3.33.392-1.264 0-2.446-.14-3.33-.392-.938-.268-1.141-.55-1.141-.607v-2.473c.25.133.56.253.929.358.953.273 2.211.423 3.541.423s2.588-.15 3.542-.423c.37-.105.679-.225.929-.358v.975c-.56.277-1.26.502-2.04.654a.39.39 0 0 0 .15.766 9.523 9.523 0 0 0 1.89-.561v.64Z"
      />
      <path
        d="M8.823 5.535a.39.39 0 0 0-.534.143l-.006.01a.39.39 0 0 0 .676.391l.007-.01a.39.39 0 0 0-.143-.534ZM18.24 19.89a.39.39 0 0 0-.428-.35h-.009a.39.39 0 1 0 .074.778l.013-.001a.39.39 0 0 0 .35-.428Z"
      />
    </g>
  </svg>
</template>
