<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".4"
      d="M8.739 13.882c-.102-.44-.757-.301-.673.142.001.004.03.145.053.386-2.454-2.013-4.33-8.802-4.349-8.874-.121-.427-.776-.263-.663.181.086.316 2.142 7.76 4.989 9.526.016.01-.033 1.184-.237 1.87-.538 1.81.27 4.18.305 4.28a.344.344 0 0 0 .65-.224c-.009-.023-.772-2.259-.296-3.86.547-1.84.235-3.363.221-3.427ZM18.163 8.44c.317-1.262.134-3.018.126-3.092a.344.344 0 0 0-.684.074c.002.016.178 1.708-.109 2.85-.183.726-1.763 1.06-2.524 1.152-.533-.775-1.29-1.508-2.272-2.193-1.907-1.33-4.299-4.678-4.323-4.712-.262-.36-.819.023-.56.399.1.141 2.485 3.48 4.49 4.877 1.935 1.35 2.916 2.868 2.916 4.513 0 .19.154.344.344.344.794-.003.034-2.173-.21-2.586.879-.137 2.53-.534 2.806-1.627ZM20.756 13.988c-1.199-.81-3.3-.583-3.389-.573a.344.344 0 1 0 .078.684c.02-.003 1.936-.211 2.927.459.376.246.756-.31.384-.57ZM12.559 14.195c-.093-1.444-1.558-2.967-1.62-3.031a.344.344 0 1 0-.493.479c.013.014 1.35 1.403 1.426 2.596.036.448.708.409.687-.044Z"
    />
  </svg>
</template>
