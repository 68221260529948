<script setup lang="ts">
import Eye from './category/Eye.vue'
import Nose from './category/Nose.vue'
import Face from './category/Face.vue'
import Injection from './category/Injection.vue'
import Lip from './category/Lip.vue'
import Laser from './category/Laser.vue'
import Skin from './category/Skin.vue'
import Internalmedicine from './category/Internalmedicine.vue'
import Breast from './category/Breast.vue'
import Scent from './category/Scent.vue'
import Ear from './category/Ear.vue'
import Hip from './category/Hip.vue'
import Hairremoval from './category/Hairremoval.vue'
import Scar from './category/Scar.vue'
import Artmake from './category/Artmake.vue'
import Dentist from './category/Dentist.vue'
import Aga from './category/Aga.vue'
import Gynecology from './category/Gynecology.vue'
import Ed from './category/Ed.vue'
import Forehead from './category/Forehead.vue'
import Neck from './category/Neck.vue'
import Hand from './category/Hand.vue'
import Side from './category/Side.vue'
import Arm from './category/Arm.vue'
import Stomach from './category/Stomach.vue'
import Back from './category/Back.vue'
import Leg from './category/Leg.vue'
import Slimming from './category/Slimming.vue'
import Pill from './category/Pill.vue'
import Hairremovalman from './category/Hairremovalman.vue'
import Others from '~/components/svg/category/Others.vue'
import Cosmetic from '~/components/svg/category/Cosmetic.vue'
import Shoulder from '~/components/svg/category/Shoulder.vue'
import Siboukyuuinnkategori from '~/components/svg/category/Siboukyuuinnkategori.vue'

type Props = {
  categorySlug?: string
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  categorySlug: '',
  color: '#FF4471',
  size: 24,
})
const components: { [key: string]: typeof Eye } = {
  eye: Eye,
  nose: Nose,
  face: Face,
  ear: Ear,
  lip: Lip,
  breast: Breast,
  body: Slimming,
  hip: Hip,
  scent: Scent,
  hairremoval: Hairremoval,
  scar: Scar,
  skin: Skin,
  artmake: Artmake,
  internalmedicine: Internalmedicine,
  dentist: Dentist,
  others: Others,
  aga: Aga,
  laser: Laser,
  injection: Injection,
  cosmetic: Cosmetic,
  gynecology: Gynecology,
  ed: Ed,
  forehead: Forehead,
  neck: Neck,
  hand: Hand,
  side: Side,
  shoulder: Shoulder,
  arm: Arm,
  stomach: Stomach,
  back: Back,
  leg: Leg,
  slimming: Slimming,
  earrings: Ear,
  pill: Pill,
  siboukyuuinnkategori: Siboukyuuinnkategori,
  hairremovalman: Hairremovalman,
}
</script>
<template>
  <component
    :is="components[props.categorySlug]"
    :color="props.color"
    :size="size"
  />
</template>
<scss lang="ts" scoped></scss>
