<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".2"
      clip-path="url(#a)"
    >
      <path d="M20.61 17.156a.39.39 0 1 0 0-.78.39.39 0 0 0 0 .78Z" />
      <path
        d="M22.609 13.805h-8.432L6.017 1.74a1.624 1.624 0 0 0-1.044-.69 1.637 1.637 0 0 0-1.665 2.522l4.924 7.28c.07.104.185.168.31.172l.95.033 1.399 2.067-.348.236a.391.391 0 0 0-.152.445h-7a.391.391 0 0 0-.391.392v6.39c0 .217.175.392.391.392H22.61a.391.391 0 0 0 .391-.391v-6.392a.391.391 0 0 0-.391-.39ZM5.744 5.778l2.109.073.334.495-2.108-.074-.335-.494ZM4.185 1.95a.846.846 0 0 1 .64-.13c.223.043.416.17.544.359l1.941 2.87-2.108-.074-1.245-1.84a.854.854 0 0 1 .228-1.185Zm2.437 5.124 2.108.074 2.146 3.174-2.108-.073-2.146-3.175Zm3.838 4.017.959.033 3.636 5.376c-1.007.118-1.935-.273-2.765-1.166a6.674 6.674 0 0 1-1.033-1.512l.396-.268a.392.392 0 0 0 .105-.544l-1.298-1.92Zm11.757 9.105H3.783v-5.608h6.998c.214.37.52.828.92 1.262.888.962 1.911 1.454 3.006 1.454.345 0 .696-.05 1.052-.147h3.099a.391.391 0 0 0 0-.783h-2.943l-1.208-1.786h7.51v5.608Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M3 1h20v20H3z" /></clipPath>
    </defs>
  </svg>
</template>
