<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g
      :transform="`scale(${scale})`"
      :fill="props.color"
      :stroke="props.color"
      stroke-width=".3"
    >
      <path
        d="M20.633 12.743c0-.68-.554-1.235-1.235-1.235h-6.67V9.694c0-.816-.663-1.48-1.48-1.48h-.496V5.334a.412.412 0 0 0-.412-.412h-.329v-.993h.247a.412.412 0 0 0 .412-.412V1.87a.412.412 0 0 0-.412-.411h-2.47c-.59 0-1.07.48-1.07 1.07v2.393h-.33a.412.412 0 0 0-.412.412v2.882H5.48c-.816 0-1.48.663-1.48 1.48v3.83a.412.412 0 0 0 .823 0v-3.83c0-.363.295-.657.657-.657h5.769c.361 0 .656.294.656.656v1.814h-1.4c-.681 0-1.235.555-1.235 1.236v1.976c0 .316.12.604.315.823a1.23 1.23 0 0 0-.315.824v4.94c0 .145.025.283.07.412H6.06a1.237 1.237 0 0 1-1.236-1.235V17.23a.412.412 0 0 0-.823 0v3.253a2.06 2.06 0 0 0 2.059 2.058h13.34c.68 0 1.234-.554 1.234-1.235v-4.94c0-.317-.12-.605-.315-.824a1.23 1.23 0 0 0 .315-.823v-1.977ZM7.541 2.528c0-.136.11-.247.247-.247h2.058v.823H9.6a.412.412 0 0 0-.411.412v1.405H7.54V2.528ZM6.8 8.215v-2.47h3.129v2.47h-3.13Zm3.293 4.528c0-.226.185-.411.412-.411h8.893c.227 0 .412.184.412.411v1.977a.412.412 0 0 1-.412.412h-8.893a.412.412 0 0 1-.412-.412v-1.977Zm9.717 8.564a.412.412 0 0 1-.412.412h-8.893a.412.412 0 0 1-.412-.412v-4.94c0-.227.185-.412.412-.412h8.893c.227 0 .412.185.412.412v4.94Z"
      />
      <path
        d="M17.584 18.425h-5.27a.412.412 0 0 0 0 .824h5.27a.412.412 0 0 0 0-.824ZM4.412 15.79a.415.415 0 0 0 .411-.411.414.414 0 0 0-.411-.412.415.415 0 0 0-.412.412.415.415 0 0 0 .412.411Z"
      />
    </g>
  </svg>
</template>
