<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path
      :transform="`scale(${scale})`"
      :fill="props.color"
      fill-rule="evenodd"
      :stroke="props.color"
      stroke-width=".3"
      d="M8.374 3.02a5.089 5.089 0 0 0-1.7.472c-1.173.584-2.067 1.628-2.437 2.843-.212.698-.26 1.19-.202 2.053.116 1.722.472 3.33 1.403 4.65l.259.449.733 3.224c.525 2.307.763 3.285.838 3.438.058.118.187.297.288.397.61.611 1.582.604 2.192-.015.35-.355.337-.297.917-3.77.369-2.21.547-3.189.6-3.293a.84.84 0 0 1 .946-.429c.155.037.247.092.385.23.1.1.195.234.211.297.016.064.262 1.506.546 3.205.466 2.787.529 3.115.638 3.35.216.46.633.77 1.16.86.636.109 1.341-.264 1.61-.85.062-.135.364-1.387.822-3.41l.724-3.195.285-.508c1.158-1.658 1.39-3.316 1.4-5.12.004-.737-.048-1.094-.242-1.669a4.794 4.794 0 0 0-3.326-3.077 6.073 6.073 0 0 0-.723-.12c-.736-.074-1.275.002-2.208.315-.746.25-1.038.311-1.485.311-.427 0-.744-.06-1.25-.24-1.012-.357-1.685-.47-2.384-.397Zm.812.68c.422.047.669.111 1.35.35.575.202.944.27 1.472.27s.898-.068 1.472-.27c1.002-.351 1.496-.432 2.17-.353.986.114 1.812.503 2.47 1.16.926.927 1.294 2.004 1.194 3.5-.108 1.63-.535 3.016-1.369 4.446l-.262.45-.73 3.223c-.4 1.773-.765 3.3-.81 3.394-.148.31-.536.508-.888.455a.868.868 0 0 1-.68-.515c-.036-.082-.285-1.477-.554-3.1a242.497 242.497 0 0 0-.533-3.145 1.538 1.538 0 0 0-1.237-1.197 1.506 1.506 0 0 0-1.665.996c-.035.102-.292 1.56-.571 3.241-.286 1.721-.538 3.123-.576 3.21-.15.34-.54.564-.9.515a.921.921 0 0 1-.744-.65 325.15 325.15 0 0 1-.751-3.282l-.711-3.146-.262-.45c-.828-1.42-1.26-2.825-1.369-4.445-.102-1.534.284-2.62 1.26-3.55A3.97 3.97 0 0 1 8.32 3.7c.395-.046.45-.046.867 0Zm-.851 1.62v.332h.664v-.665h-.664v.332Zm-1.07-.198C6.6 5.396 6.04 6 5.834 6.661a3.563 3.563 0 0 0-.155.876v.147h.664v-.147c.002-.247.11-.681.235-.934.19-.388.649-.773 1.098-.924.057-.019.057-.048.002-.337-.069-.361-.068-.36-.411-.22Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
