<script setup lang="ts">
const DEFAULT_SIZE = 24
type Props = {
  color?: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  color: '#FF4471',
  size: DEFAULT_SIZE,
})
const scale = computed(() => props.size / DEFAULT_SIZE)
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :view-box="`0 0 ${size} ${size}`"
    fill="none"
  >
    <g :transform="`scale(${scale})`">
      <path
        :fill="props.color"
        d="M15.608 3.446a.485.485 0 0 0-.433-.69.487.487 0 0 0-.457.298l-.002.004-.006.015c-.043.095-.086.19-.13.283a45.49 45.49 0 0 1-1.828 3.51c-1.206 2.08-2.792 4.385-4.393 5.539-2.113 1.516-2.748 3.381-2.303 4.942.436 1.53 1.88 2.62 3.584 2.62h1.57a1.278 1.278 0 0 1 1.278 1.277h.973a2.25 2.25 0 0 0-2.25-2.25h-.28c.108-.163.282-.323.547-.446.483-.226 1.303-.34 2.602-.04a.487.487 0 1 0 .22-.947c-1.424-.33-2.485-.244-3.233.105-.66.308-1.048.81-1.197 1.328h-.23c-1.287 0-2.337-.819-2.648-1.914-.304-1.063.059-2.539 1.934-3.885h.001c1.785-1.287 3.459-3.758 4.667-5.841a46.503 46.503 0 0 0 2.004-3.885l.007-.016.003-.006Zm2.354 13.903c-.253 1.017-.884 1.936-1.57 2.363l-.514-.826c.429-.268.935-.947 1.14-1.771.198-.801.094-1.658-.565-2.342l.7-.675c.948.983 1.067 2.211.808 3.251h.001Z"
      />
      <path
        :stroke="props.color"
        stroke-width=".444"
        d="M15.608 3.446a.485.485 0 0 0-.433-.69.487.487 0 0 0-.457.298l-.002.004-.006.015c-.043.095-.086.19-.13.283a45.455 45.455 0 0 1-1.828 3.51c-1.206 2.08-2.792 4.385-4.393 5.539-2.113 1.516-2.748 3.381-2.303 4.942.436 1.53 1.88 2.62 3.584 2.62h1.57a1.278 1.278 0 0 1 1.278 1.277h.973a2.25 2.25 0 0 0-2.25-2.25h-.28c.108-.163.282-.323.547-.446.483-.226 1.303-.34 2.602-.04a.487.487 0 1 0 .22-.947c-1.424-.33-2.485-.244-3.233.105-.66.308-1.048.81-1.197 1.328h-.23c-1.287 0-2.337-.819-2.648-1.914-.304-1.063.059-2.539 1.934-3.885h.001c1.785-1.287 3.459-3.758 4.667-5.841a46.468 46.468 0 0 0 2.004-3.885l.007-.016.003-.006Zm0 0-.445-.196m2.799 14.1c-.253 1.016-.884 1.935-1.57 2.362l-.514-.826c.429-.268.935-.947 1.14-1.771.198-.801.094-1.658-.565-2.342l.7-.675c.948.983 1.067 2.211.808 3.251h.001Z"
      />
    </g>
  </svg>
</template>
